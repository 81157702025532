import React, {Component, useEffect} from "react";
import {ButtonsComponent} from "./ButtonsComponent";
import {TableComponent} from "./TableComponent";
import {MapComponent} from "./MapComponent";
import {ChartComponent} from "./ChartComponent";
import {FiltersComponent} from "./FiltersComponent";
import {useLocation} from "react-router-dom";

class PricingMap extends Component {
    static displayName = PricingMap.name;

    constructor(props) {
        super(props);
        const searchParams = props.queryParameters;

        let coords = "32.019377,-96.810219"
        if (searchParams.get('coords'))
            coords = searchParams.get('coords');

        let zoomSize = "15";
        if (searchParams.get('zoomSize'))
            zoomSize = searchParams.get('zoomSize');

        let version = "1";
        if (searchParams.get('version'))
            version = searchParams.get('version');

        if (version !== "3")
            window.alert("Please re-run pricing from JIRA to upgrade to latest Version 3.");

        let listingIds = "";
        if (searchParams.get('listingIds'))
            listingIds = searchParams.get('listingIds');

        this.state = {
            zoomSize: zoomSize,
            coords: coords,
            version: version,
            listingIds: listingIds,
            lands: [],
            filteredLands: [],
            loading: true,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {land: null},
            searchParams: searchParams,
            selectedComps: [],
            dealKey: searchParams.get('dealKey'),
            issueId: searchParams.get('issueId'),
            pricingProgress: 0,
            pricePerAcre: "...",
            price: "...",
            landMarkers: [],
            acreRange: [0, 100],
            distanceMiles: 50,
            daysSinceSaleRange: [0, 365],
            compType: "solds"
        };
    }

    componentDidMount() {
        if (this.state.version === "3") {
            this.populateMapDataV3('solds');
        } else {
            this.populateMapData();
        }
    }

    addLandToSelected = (land) => {
        if (!this.state.selectedComps.some((a) => a.localId === land.localId)) {
            this.setState(prevState => ({
                selectedComps: [...prevState.selectedComps, land]
            }))
        }
    }

    removeLandFromSelected = (land) => {
        const array = [...this.state.selectedComps]; // make a separate copy of the array
        const index = array.findIndex((element) => {
            return element.localId === land.localId
        })
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({selectedComps: array});
        }
    }

    setActiveMarker = (land) => {
        if (this.state.landMarkers.find(a => a.land.localId === land.localId)) {
            const marker = this.state.landMarkers.find(a => a.land.localId === land.localId).marker;
            this.setState({
                selectedPlace: {land: land},
                activeMarker: marker,
                showingInfoWindow: true,
            });
        }
    };

    removeActiveMarker = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                selectedPlace: {land: null},
                activeMarker: null,
                showingInfoWindow: false,
            });
        }
    };

    switchCompType = (compType) => {
        
        if (compType === 'for-sales') {
            this.setState({loading: true})
            this.populateMapDataV3('for-sales');
        } else {
            this.setState({loading: true})
            this.populateMapDataV3('solds');
        }
        this.setState({pricePerAcre: "...", price: "...", pricingProgress: 0})
    };

    addToLandMarkers = (land, marker) => {
        if (!this.state.landMarkers.some((a) => a.land.localId === land.localId)) {
            this.setState(prevState => ({
                landMarkers: [...prevState.landMarkers, {land: land, marker: marker}]
            }))
        }
    };

    updateState = (object) => this.setState(object);

    getState = () => this.state;

    getMethods = () => {
        return {
            setActiveMarker: this.setActiveMarker,
            addLandToSelected: this.addLandToSelected,
            removeLandFromSelected: this.removeLandFromSelected,
            removeActiveMarker: this.removeActiveMarker,
            populateMapData: this.populateMapData,
            priceDeal: this.priceDeal,
            addToLandMarkers: this.addToLandMarkers,
            switchCompType: this.switchCompType
        }
    }

    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (
            renderBody(this.getState, this.updateState, this.getMethods)
        );

        return <div>{contents}</div>;
    }

    async populateMapDataV3(resource) {
        const uri = `lands/v3/${resource}`;
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(
                {
                    issueId: this.state.issueId,
                    version: this.state.version
                }
            )
        };
        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        this.setState({lands: data.sales, selectedComps: data.selectedComps, coords: data.coords, loading: false});
    }

    async populateMapData() {
        const uri = 'lands/sales';
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(
                {
                    zoomSize: this.state.zoomSize,
                    coords: this.state.coords,
                    version: this.state.version,
                    listingIds: this.state.listingIds,
                }
            )
        };
        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        this.setState({lands: data.sales, selectedComps: data.closestSales, loading: false});
    }

    async priceDeal(getState, updateState) {
        const uri = 'lands/price';
        const state = getState();

        updateState({pricingProgress: 1})

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                sales: state.selectedComps,
                dealKey: state.dealKey,
                issueId: state.issueId,
                compType: state.compType,
                version: state.version
            })
        };

        const response = await fetch(uri, requestOptions);
        const data = await response.json();

        if (data) {
            updateState({pricingProgress: 2})
            updateState({price: data.price, pricePerAcre: data.pricePerAcre});
        } else {
            updateState({pricingProgress: 3});
        }
    }
}

const renderBody = function (getState, updateState, getMethods) {

    const lat = Number(getState().coords.split(",")[0].trim());
    const lng = Number(getState().coords.split(",")[1].trim());

    const getNewFeature = function () {
        if (getState().dealKey || getState().issueId)
            return (
                <div>
                    <div>
                        <ButtonsComponent getState={getState}
                                          updateState={updateState}
                                          getMethods={getMethods}/>
                    </div>
                    <div style={{width: "600px", height: "400px"}}>
                        <ChartComponent getState={getState}/>
                    </div>
                    <div>
                        <TableComponent getState={getState}
                                        updateState={updateState}
                                        getMethods={getMethods}/>
                    </div>
                </div>
            );
        return null;
    };

    return (
        <div>
            <FiltersComponent getState={getState}
                              updateState={updateState}
                              getMethods={getMethods}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px"
                }}
            >
                <MapComponent lat={lat} lng={lng}
                              getState={getState}
                              updateState={updateState}
                              getMethods={getMethods}/>
                {getNewFeature()}
            </div>
        </div>
    );
};

function withQueryParameters(Component) {
    return function WrappedComponent(props) {
        const location = useLocation();
        const queryParameters = new URLSearchParams(location.search);
        return <Component {...props} queryParameters={queryParameters}/>;
    }
}

export default withQueryParameters(PricingMap);


