import {Status, Wrapper} from "@googlemaps/react-wrapper";
import React, {Component} from "react";
import {
    CartesianGrid,
    Legend, Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {InfoWindow} from "../pricingMap/map/InfoWindow";
import {MarkerNew} from "./MarkerNew";
import {Map} from "../pricingMap/map/Map";
import {useLocation} from "react-router-dom";


class HotnessMap extends Component {
    static displayName = HotnessMap.name;

    constructor(props) {
        super(props);
        const searchParams = props.queryParameters;

        let dist = "50";
        if (searchParams.get('dist'))
            dist = searchParams.get('dist');

        let months = "12";
        if (searchParams.get('months'))
            months = searchParams.get('months');

        this.state = {
            market: {},
            coords: searchParams.get('coords'),
            loading: true,
            dist: dist,
            months: months,
            subMarkets: [],
            selectedObject: {},
            activeMarker: {},
            showingInfoWindow: false,
            objectMarkers: []
        };
    }

    componentDidMount() {
        this.populateMarket();
    }

    getState = () => this.state;
    updateState = (object) => this.setState(object);

    setActiveMarker = (object) => {
        const marker = this.state.objectMarkers.find(a => a.object.id === object.id).marker;
        this.setState({
            selectedObject: object,
            activeMarker: marker,
            showingInfoWindow: true,
        });
    };

    removeActiveMarker = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                selectedObject: null,
                activeMarker: null,
                showingInfoWindow: false,
            });
        }
    };

    addToMarkers = (object, marker) => {
        if (!this.state.objectMarkers.some((a) => a.object.id === object.id)) {
            this.setState(prevState => ({
                objectMarkers: [...prevState.objectMarkers, {object: object, marker: marker}]
            }))
        }
    };

    getMethods = () => {
        return {
            setActiveMarker: this.setActiveMarker,
            removeActiveMarker: this.removeActiveMarker,
            addToMarkers: this.addToMarkers
        }
    }

    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (
            renderBody(this.getState, this.updateState, this.getMethods)
        );

        return <div>{contents}</div>;
    }

    async populateMarket() {
        const uri = 'markets/hotness';
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                latLon: this.state.coords,
                distance: this.state.dist,
                months: this.state.months
            })
        };

        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        this.setState({market: data.market, subMarkets: data.subMarkets, loading: false});
    }
}

const renderBody = function (getState, updateState, getMethods) {

    const lat = Number(getState().coords.split(",")[0].trim());
    const lng = Number(getState().coords.split(",")[1].trim());

    const state = getState();

    const market = getState().market;
    const subMarkets = getState().subMarkets;

    const displayMarkers = function () {

        return subMarkets
            .map((subMarket) => {
                return (
                    <MarkerNew
                        key={subMarket.id}
                        position={{
                            lat: subMarket.lat,
                            lng: subMarket.lon,
                        }}
                        icon={{
                            url: getIcon(subMarket.landSegment.hotnessCategory),
                        }}
                        object={subMarket}
                        getMethods={getMethods}
                    />
                );
            });
    };

    const getInfoWindowText = function (market) {

        const landSegment = market.landSegment;

        if (!landSegment) {
            return (
                <div>
                    No Listings Found <br/>
                </div>
            );
        }

        return (
            <div>
                SegmentType: {landSegment.segmentType} <br/>
                HotnessScore: {landSegment.hotnessScore} <br/>
                HotnessCategory: {landSegment.hotnessCategory} <br/>
                MedianDom: {landSegment.medianDom} <br/>
                MedianSoldPrice: {landSegment.medianSoldPrice} <br/>
                MedianForSalePrice: {landSegment.medianForSalePrice} <br/>
                SoldCount: {landSegment.soldCount} <br/>
                PendingCount: {landSegment.pendingCount} <br/>
                ForSaleCount: {landSegment.forSaleCount} <br/>
                Sold % Change: {landSegment.soldPercentChange} <br/>
                NewListings % Change: {landSegment.newListingsPercentChange} <br/>
                DomCount: {landSegment.domCount} <br/>
                ListingCount: {landSegment.listingCount} <br/>
                MaximumDom: {landSegment.maximumDom} <br/>
            </div>
        );
    };

    const getIcon = function (category) {

        if (category === 1) {
            return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/locations/purple.png";
        } else if (category === 2) {
            return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/locations/red.png";
        } else if (category === 3) {
            return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/locations/orange.png";
        } else if (category === 4) {
            return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/locations/yellow.png";
        } else {
            return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/locations/light-green.png";
        }
    };

    const render = (status) => {
        if (status === Status.FAILURE) return "Error";
        return "Loading...";
    };

    const sortBy = (func) => {
        const orig = [...state.segments];
        const sorted = orig.sort(func)
        updateState({segments: sorted})
    };

    //const data = state.market.trendPointsByMonth;
    const data = market.landSegment.trendPointsByTimespan;

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px"
                }}
            >
                <LineChart
                    width={650}
                    height={600}
                    data={data}
                    margin={{
                        top: 20,
                        right: 5,
                        bottom: 20,
                        left: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Line type="monotone" dataKey="medianPrice" name="Median Price" stroke="#82ca9d"/>
                    <Line type="monotone" dataKey="averagePrice" name="Average Price" stroke="#8884d8"/>
                </LineChart>
                <LineChart
                    width={650}
                    height={600}
                    data={data}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Line type="monotone" dataKey="soldCount" name="Sales Velocity" stroke="#8884d8"/>
                    <Line type="monotone" dataKey="soldCountChange" name="Sales Acceleration" stroke="#82ca9d"/>
                </LineChart>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px"
                }}
            >
                <LineChart
                    width={650}
                    height={600}
                    data={data}
                    margin={{
                        top: 20,
                        right: 5,
                        bottom: 20,
                        left: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Line type="monotone" dataKey="medianPriceChange" name="Median Price Acceleration"
                          stroke="#82ca9d"/>
                    <Line type="monotone" dataKey="averagePriceChange" name="Average Price Acceleration"
                          stroke="#8884d8"/>
                </LineChart>
            </div>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col">Segment Type</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.medianDom - b.medianDom)}>Median Dom</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.soldCount - b.soldCount)}>Sold Count</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.pendingCount - b.pendingCount)}>Pending Count</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.forSaleCount - b.forSaleCount)}>ForSale Count</th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.medianSoldPrice - b.medianSoldPrice)}>Median Sold
                        Price
                    </th>
                    <th scope="col" onClick={() => sortBy((a, b) => a.medianForSalePrice - b.medianForSalePrice)}>Median
                        ForSale Price
                    </th>
                    <th scope="col">Sold ForSale Ratio 1 Year</th>
                    <th scope="col">Pending Sold Ratio 1 Month</th>
                    <th scope="col">Sold % Change 10 Days</th>
                </tr>
                </thead>
                <tbody>
                {market.segments.map((segment, index) =>
                    <tr key={segment.id}>
                        <td>{segment.segmentType}</td>
                        <td>{segment.medianDom}</td>
                        <td>{segment.soldCount}</td>
                        <td>{segment.pendingCount}</td>
                        <td>{segment.forSaleCount}</td>
                        <td>{segment.medianSoldPrice}</td>
                        <td>{segment.medianForSalePrice}</td>
                        <td>{segment.soldForSaleRatio}</td>
                        <td>{segment.pendingSoldRatio}</td>
                        <td>{segment.soldPercentChange}</td>
                    </tr>
                )}
                </tbody>
            </table>
            <Wrapper apiKey="AIzaSyCGGO3uwp6FRmVMdjcrXdTLVXyAOdn_v4U" render={render}>
                <Map
                    zoom={10}
                    center={{lat: lat, lng: lng}}
                    style={{width: "1290px", height: "1250px"}}
                    onClick={getMethods().removeActiveMarker}
                >
                    {displayMarkers()}
                    <InfoWindow
                        visible={getState().showingInfoWindow}
                        marker={getState().activeMarker}
                    >
                        {getInfoWindowText(getState().selectedObject)}
                    </InfoWindow>
                </Map>
            </Wrapper>
        </div>
    );
};

function withQueryParameters(Component) {
    return function WrappedComponent(props) {
        const location = useLocation();
        const queryParameters = new URLSearchParams(location.search);
        return <Component {...props} queryParameters={queryParameters}/>;
    }
}

export default withQueryParameters(HotnessMap);