import React from 'react';
import {ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';

export function ChartComponent(props) {

    const getState = props.getState;
    
    const data = getState().selectedComps.map((comp, index) => {
        return {
            x: comp.acres,
            y: parseFloat(comp.salePricePerAcre.replace('$', '').replace(',', '')),
            label: index
        };
    });
    
    return (
        <ResponsiveContainer width="100%" height="100%">
            <ScatterChart
                width={400}
                height={400}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid/>
                <XAxis type="number" dataKey="x" name="acres" unit=" acres"/>
                <YAxis type="number" dataKey="y" name="$ per acre" unit=" $/acre"/>
                <Tooltip cursor={{strokeDasharray: '3 3'}}/>
                <Scatter name="pricing" data={data} fill="#8884d8">
                </Scatter>
            </ScatterChart>
        </ResponsiveContainer>
    );
}

//https://recharts.org/en-US/examples/ScatterChartWithLabels