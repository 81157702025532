import React from "react";

export function TableComponent(props) {

    const getState = props.getState;
    const updateState = props.updateState;
    const getMethods = props.getMethods;

    return (
        <div>
            {getTable(getState, getMethods, updateState)}
        </div>
    );
}

const getTable = function (getState, getMethods, updateState) {

    const state = getState();

    const sortBy = (func) => {
        const comps = [...state.selectedComps];
        const sorted = comps.sort(func)
        updateState({selectedComps: sorted})
    };

    const rows = getState().selectedComps.map((comp, index) => {

        let classStyle = "";
        const selectedLand = getState().selectedPlace.land;

        if (selectedLand && selectedLand.localId === comp.localId) {
            classStyle = "table-warning";
        }

        return (
            <tr key={index} onClick={() => getMethods().setActiveMarker(comp)} className={classStyle}>
                <th scope="row">{index + 1}</th>
                <td>{comp.acres}</td>
                <td>{comp.salePrice}</td>
                <td>{comp.salePricePerAcre}</td>
                <td>{comp.daysOnMarket}</td>
                <td>{comp.saleDate}</td>
                <td><a href={comp.redfinUrl} target="_blank" rel="noopener noreferrer">Link</a></td>
                <td>
                    <button type="button" className="btn btn-danger btn-sm"
                            onClick={() => getMethods().removeLandFromSelected(comp)}>Delete
                    </button>
                </td>
            </tr>
        );
    });

    return (
        <table className="table table-striped">
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col" onClick={() => sortBy((a, b) => a.acres - b.acres)}>Acres</th>
                <th scope="col" onClick={() => sortBy((a, b) => a.salePriceNumber - b.salePriceNumber)}>$</th>
                <th scope="col" onClick={() => sortBy((a, b) => a.salePricePerAcreNumber - b.salePricePerAcreNumber)}>
                    $ / Acre
                </th>
                <th scope="col" >DOM</th>
                <th scope="col" onClick={() => sortBy((a, b) => a.daysSinceSale - b.daysSinceSale)}>Date</th>
                <th scope="col">Link</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>
    );
}