import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export function FiltersComponent(props) {

    const getMethods = props.getMethods;
    const state = props.getState();
    const updateState = props.updateState;

    const handleAcresChange = (event, newValue) => {
        updateState({acreRange: newValue})
    };

    const handleDistanceChange = (event, newValue) => {
        updateState({distanceMiles: newValue})
    };

    const handleDaysSinceSaleChange = (event, newValue) => {
        updateState({daysSinceSaleRange: newValue})
    };

    const handleCompTypeChange = (event, newValue) => {
        updateState({compType: newValue})
        getMethods().switchCompType(newValue)
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "30px",
                justifyContent: "center"
            }}
        >
            <Box sx={{width: 300}}>
                Acres
                <Slider
                    value={state.acreRange}
                    onChange={handleAcresChange}
                    valueLabelDisplay="auto"
                />
            </Box>
            <Box sx={{width: 250}}>
                Max Miles from Target
                <Slider
                    value={state.distanceMiles}
                    onChange={handleDistanceChange}
                    valueLabelDisplay="auto"
                    max={50}
                />
            </Box>
            <Box sx={{width: 300}}>
                Days Since Sale
                <Slider
                    value={state.daysSinceSaleRange}
                    onChange={handleDaysSinceSaleChange}
                    valueLabelDisplay="auto"
                    max={365}
                />
            </Box>
            <Box sx={{width: 300}}>
                <ToggleButtonGroup
                    color="primary"
                    size="large"
                    value={state.compType}
                    exclusive
                    onChange={handleCompTypeChange}
                    aria-label="Platform"
                >
                    <ToggleButton value="solds">Solds</ToggleButton>
                    <ToggleButton value="for-sales">For Sales</ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </div>
    );
}
