import React, {Component} from 'react';
import {Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {Link, useLocation} from 'react-router-dom';
import './NavMenu.css';
import {SignIn} from "../auth/SignIn";

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            queryParameters: props.queryParameters
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
                        container light>
                    <NavbarBrand tag={Link} to="/">DealsApp</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2"/>
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to={`/?${this.state.queryParameters}`}>Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark"
                                         to={`/sales-map?${this.state.queryParameters}`}>Pricing</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark"
                                         to={`/sales-listings?${this.state.queryParameters}`}>Listings</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark"
                                         to={`/realtors?${this.state.queryParameters}`}>Realtors</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to={`/hotness-map?${this.state.queryParameters}`}>Hotness</NavLink>
                            </NavItem>
                            <SignIn/>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}

function withQueryParameters(Component) {
    return function WrappedComponent(props) {
        const location = useLocation();
        const queryParameters = new URLSearchParams(location.search);
        return <Component {...props} queryParameters={queryParameters}/>;
    }
}

export default withQueryParameters(NavMenu);
