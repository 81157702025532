import React, {Component} from "react";
import {useLocation} from "react-router-dom";
import {DataGrid} from '@mui/x-data-grid';


class SalesListings extends Component {
    static displayName = SalesListings.name;

    constructor(props) {
        super(props);
        const queryParameters = props.queryParameters;

        if (!queryParameters.get('issueId'))
            window.alert("Deals App must be opened from Deal Card in JIRA.");

        let version = "1";
        if (queryParameters.get('version'))
            version = queryParameters.get('version');

        if (version !== "3")
            window.alert("Please re-run pricing from JIRA to upgrade to latest Version 3.");

        this.state = {
            loading: true,
            version: version,
            issueId: queryParameters.get('issueId'),
            soldListings: [],
            forSaleListings: []
        };
    }

    updateState = (object) => this.setState(object);

    getState = () => this.state;

    componentDidMount() {
        this.populateListings();
    }

    async populateListings() {
        const uri = `salesListings`;
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(
                {
                    issueId: this.state.issueId
                }
            )
        };
        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        this.setState({soldListings: data.soldListings, forSaleListings: data.forSaleListings, loading: false});
    }

    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (
            renderBody(this.getState, this.updateState)
        );

        return <div>{contents}</div>;
    }
}

const renderBody = function (getState, updateState) {
    const state = getState();
    
    return (
        <div style={{height: '100%', width: '100%'}}>
            For Sale Listings
            <DataGrid
                rows={state.forSaleListings}
                columns={forSaleColumns}
                initialState={{
                    pagination: {
                        paginationModel: {page: 0, pageSize: 10},
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
            />
            Sold Listings
            <DataGrid
                rows={state.soldListings}
                columns={soldColumns}
                initialState={{
                    pagination: {
                        paginationModel: {page: 0, pageSize: 10},
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
            />
        </div>
    );
}

let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
});

const forSaleColumns = [
    {field: 'street', headerName: 'Address', width: 200},
    {field: 'location', headerName: 'Location', width: 150},
    {
        field: 'price', headerName: 'Price', type: 'number', width: 100,
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }
            return `${USDollar.format(params.value)}`;
        }
    },
    {
        field: 'pricePerAcre', headerName: '$/Acre', type: 'number', width: 100,
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }
            return `${USDollar.format(params.value)}`;
        }
    },
    {field: 'acres', headerName: 'Acres', type: 'number', width: 75},
    {field: 'daysOnMarket', headerName: 'DOM', type: 'number', width: 75},
    {
        field: 'url', headerName: 'Url', width: 130,
        renderCell: (params) => (
            <a href={params.value} target="_blank">Link</a>
        )
    },
];

const soldColumns = [
    {field: 'street', headerName: 'Address', width: 200},
    {field: 'location', headerName: 'Location', width: 150},
    {
        field: 'price', headerName: 'Price', type: 'number', width: 100,
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }
            return `${USDollar.format(params.value)}`;
        }
    },
    {
        field: 'pricePerAcre', headerName: '$/Acre', type: 'number', width: 100,
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }
            return `${USDollar.format(params.value)}`;
        }
    },
    {field: 'acres', headerName: 'Acres', type: 'number', width: 75},
    {field: 'soldDate', headerName: 'Sold Date', width: 100,
        valueFormatter: (params) => {
            if (params.value == null) {
                return '';
            }
            return new Date(Date.parse(params.value)).toLocaleDateString('en-us');
        }},
    {
        field: 'url', headerName: 'Url', width: 130,
        renderCell: (params) => (
            <a href={params.value} target="_blank">Link</a>
        )
    },
];

function withQueryParameters(Component) {
    return function WrappedComponent(props) {
        const location = useLocation();
        const queryParameters = new URLSearchParams(location.search);
        return <Component {...props} queryParameters={queryParameters}/>;
    }
}

export default withQueryParameters(SalesListings);
