import React, {Component, useEffect, useState} from 'react';
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import {Map} from "../pricingMap/map/Map";
import {Marker} from "../pricingMap/map/Marker";
import {useLocation} from "react-router-dom";

class RealtorMap extends Component {
    static displayName = RealtorMap.name;

    constructor(props) {
        super(props);
        const searchParams = props.queryParameters;

        let dist = "75";
        if (searchParams.get('dist'))
            dist = searchParams.get('dist');

        let months = "12";
        if (searchParams.get('months'))
            months = searchParams.get('months');

        this.state = {
            agents: [],
            coords: searchParams.get('coords'),
            loading: true,
            dist: dist,
            months: months,
            activeAgent: null
        };
    }

    componentDidMount() {
        this.populateBuyers();
    }

    setActiveAgent = (agent) => {
        this.setState({
            activeAgent: agent
        });
    };

    getState = () => this.state;

    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (renderBody(this.getState, this.setActiveAgent));

        return (
            <div>
                <h1 id="tabelLabel">Realtors Representing Buyers</h1>
                <p>
                    Leaderboard of realtors representing only the buy side, in closed transactions.
                    Vacant land deals only.
                    Within {this.state.dist} miles of target.
                    From {this.state.months} months in the past.
                </p>
                <br/>
                {contents}
            </div>
        );
    }

    async populateBuyers() {
        const uri = 'listings/buyers';
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                latLon: this.state.coords,
                dist: this.state.dist,
                months: this.state.months
            })
        };

        const response = await fetch(uri, requestOptions);
        const data = await response.json();
        this.setState({agents: data, loading: false});
    }
}

const renderBody = function (getState, setActiveAgent) {
    const urlTable = function () {
        if (getState().activeAgent) {
            const activeAgent = getState().activeAgent;

            console.log(activeAgent.horizontalDistanceMiles)

            return (
                <div>
                    <Wrapper apiKey="AIzaSyCGGO3uwp6FRmVMdjcrXdTLVXyAOdn_v4U" render={render}>
                        <Map
                            zoom={10}
                            center={{lat: activeAgent.centLat, lng: activeAgent.centLon}}
                            style={{width: "550px", height: "600px"}}
                        >
                            {displayMarkers(getState)}
                            {displayTarget(getState().coords)}
                        </Map>
                    </Wrapper>
                    <table className='table table-striped' aria-labelledby="tabelLabel">
                        <thead>
                        <tr key={"legend2"}>
                            <th>Closed Deal Urls for: {activeAgent.name}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {activeAgent.deals.map(deal =>
                            <tr key={deal.number}>
                                <td><a href={deal.url} target="_blank" rel="noopener noreferrer">{deal.url}</a></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            );
        }
        return null;
    };

    const render = (status) => {
        if (status === Status.FAILURE) return "Error";
        return "Loading...";
    };

    const displayMarkers = function (getState) {

        const activeDeals = getState().activeAgent.deals;

        return activeDeals
            .map((deal) => {
                return (
                    <Marker
                        key={deal.number}
                        position={{
                            lat: deal.lat,
                            lng: deal.lon,
                        }}
                        icon={{
                            url: "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/geotags/red.png",
                        }}
                    />
                );
            });
    };

    const displayTarget = function (coords) {
        const myArray = coords.split(",");
        const lat = Number(myArray[0]);
        const lon = Number(myArray[1]);

        return (
            <Marker
                key="Target"
                position={{
                    lat: lat,
                    lng: lon,
                }}
                icon={{
                    url: "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/geotags/purple.png",
                }}
            />
        );
    };


    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px"
            }}
        >
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                <tr key={"legend1"}>
                    <th>Name</th>
                    <th>Deal Count</th>
                    <th>Median Acres</th>
                    <th>Median Price</th>
                    <th>Agency Name</th>
                </tr>
                </thead>
                <tbody>
                {getState().agents.map(agent =>
                    <tr key={agent.Id} onClick={() => setActiveAgent(agent)}>
                        <td>{agent.name}</td>
                        <td>{agent.transactionCount}</td>
                        <td>{agent.medianAcres}</td>
                        <td>{agent.medianPrice}</td>
                        <td>{agent.agencyName}</td>
                    </tr>
                )}
                </tbody>
            </table>
            {urlTable()}
        </div>
    );
};

function withQueryParameters(Component) {
    return function WrappedComponent(props) {
        const location = useLocation();
        const queryParameters = new URLSearchParams(location.search);
        return <Component {...props} queryParameters={queryParameters}/>;
    }
}

export default withQueryParameters(RealtorMap);