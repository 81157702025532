export function ButtonsComponent(props) {

    const getMethods = props.getMethods;
    const getState = props.getState;
    const updateState = props.updateState;

    const state = getState();

    const pricingProgress = state.pricingProgress;

    const replaceSelectedComps = () => {
        updateState({selectedComps: state.filteredLands})
    };

    const label = function () {
        if (pricingProgress === 1)
            return (<span className="text-warning">In-Progress...</span>);

        if (pricingProgress === 2)
            return (<span className="text-success">Success</span>);

        if (pricingProgress === 3)
            return (<span className="text-danger">Falied</span>);

        return (<span className="text-primary">Ready</span>);
    }

    return (
        <div>
            <div className="container">
                <div className="row" style={{paddingBottom: 10}}>
                    <div className="col">
                        Price: {getState().price}
                    </div>
                    <div className="col">
                        Price/Acre: {getState().pricePerAcre}
                    </div>
                </div>
                <div className="row" style={{paddingBottom: 10}}>
                    <div className="col">
                        {label()}
                    </div>
                    {/*          <div className="col">
                        <button className="btn btn-primary"
                                onClick={replaceSelectedComps}>
                            Replace Comps
                        </button>
                    </div>*/}
                    <div className="col">
                        <button className="btn btn-primary"
                                onClick={() => getMethods().priceDeal(getState, updateState)}>
                            Save & Price
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}