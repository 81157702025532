import {Home} from "./pages/Home";
import PricingMap from "./pages/pricingMap/PricingMap";
import RealtorMap from "./pages/realtorMap/RealtorMap";
import HotnessMap from "./pages/hotnessMap/HotnessMap";
import SalesListings from "./pages/salesListings/SalesListings";


const AppRoutes = [
    {
        index: true,
        element: <Home/>
    },
    {
        path: '/sales-map',
        element: <PricingMap/>
    },
    {
        path: '/sales-listings',
        element: <SalesListings/>
    },
    {
        path: '/realtors',
        element: <RealtorMap/>
    },
    {
        path: '/hotness-map',
        element: <HotnessMap/>
    }
];

export default AppRoutes;
