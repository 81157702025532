import {Status, Wrapper} from "@googlemaps/react-wrapper";
import React, {useEffect} from "react";
import {Map} from "./map/Map";
import {InfoWindow} from "./map/InfoWindow";
import {Marker} from "./map/Marker";

export function MapComponent(props) {

    const getMethods = props.getMethods;
    const getState = props.getState;
    const updateState = props.updateState;

    return (
        <Wrapper apiKey="AIzaSyCGGO3uwp6FRmVMdjcrXdTLVXyAOdn_v4U" render={render}>
            <Map
                zoom={12}
                center={{lat: props.lat, lng: props.lng}}
                style={{width: "1290px", height: "1250px"}}
                onClick={getMethods().removeActiveMarker}
            >
                {displayMarkers(getState, updateState, getMethods)}
                {displayTarget(props.lat, props.lng, getMethods().onMarkerClick)}
                <InfoWindow
                    marker={getState().activeMarker}
                    visible={getState().showingInfoWindow}
                >
                    {getInfoWindowText(getState().selectedPlace.land)}
                </InfoWindow>
            </Map>
        </Wrapper>
    );
}

const render = (status) => {
    if (status === Status.FAILURE) return "Error";
    return "Loading...";
};

export const getInfoWindowText = function (land) {
    if (!land)
        return (
            <div>
                Land To Price
            </div>
        );

    if (land?.saleDate)
        return (
            <div>
                Acres: {land.acres} <br/>
                Price: {land.salePrice} <br/>
                $/Acre: {land.salePricePerAcre} <br/>
                DOM: {land.daysOnMarket} <br/>
                Date: {land.saleDate} <br/>
                Url: <a href={land.redfinUrl} target="_blank" rel="noopener noreferrer">Link</a> <br/>
            </div>
        );

    return (
        <div>
            Acres: {land.acres} <br/>
            Expiration: {land.offerExpiration ? land.offerExpiration : "None"}
        </div>
    );
};

export const displayMarkers = function (getState, updateState, getMethods) {

    const acreRange = getState().acreRange;
    const distanceMiles = getState().distanceMiles;
    const daysSinceSaleRange = getState().daysSinceSaleRange;
    const lands = getState().lands;

    const filteredLands = lands
        .filter(l => (l.acres >= acreRange[0] && l.acres <= acreRange[1]) || (l.acres >= acreRange[0] && acreRange[1] >= 100))
        .filter(l => l.milesFromTarget <= distanceMiles || distanceMiles >= 50)
        .filter(l => (l.daysSinceSale >= daysSinceSaleRange[0] && l.daysSinceSale <= daysSinceSaleRange[1]) || (l.daysSinceSale >= daysSinceSaleRange[0] && daysSinceSaleRange[1] >= 365));

    return filteredLands
        .map((land) => {
            return (
                <Marker
                    key={land.localId}
                    position={{
                        lat: land.latitude,
                        lng: land.longitude,
                    }}
                    icon={{
                        url: getIcon(false, getState().selectedComps.some((element) => {
                            return element.localId === land.localId
                        })),
                    }}
                  //  content={getInfoWindowText(land)}
                    land={land}
                    getMethods={getMethods}
                />
            );
        });
};

export const displayTarget = function (lat, lng, onMarkerClick) {
    return (
        <Marker
            key="TargetLand"
            onClick={onMarkerClick}
            position={{
                lat: lat,
                lng: lng,
            }}
            icon={{
                url: getIcon(true, false),
            }}
        />
    );
};

const getIcon = function (isTarget, isSelected) {
    if (isTarget)
        return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/geotags/purple.png";

    if (isSelected)
        return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/geotags/red.png";


    return "https://storage.googleapis.com/artifacts.landpal.land/icons/maps/material/geotags/blue.png";
};