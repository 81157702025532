import {useMsal} from "@azure/msal-react";
import {useEffect, useState} from "react";
import {loginRequest} from "./AuthConfig";
import {callMsGraph} from "./Graph";

//Auth Tutorial: https://learn.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
const Authorization = props => {
    const {instance, accounts} = useMsal();
    const {children} = props;
    const [authorized, setAuthorized] = useState(false);

    useEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance.acquireTokenSilent(request).then((response) => {
            callMsGraph(response.accessToken).then(response => authorize(response));
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                callMsGraph(response.accessToken).then(response => authorize(response));
            });
        });

    }, [instance]);

    const authorize = async (graph) => {
        try {
            const uri = 'authorization';
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userName: graph.userPrincipalName,
                })
            };
            const response = await fetch(uri, requestOptions);
            const data = await response.json();
            setAuthorized(data.authorized);
        } catch (error) {
            console.log("error", error);
        }
    };

    if (authorized)
        return <>{children}</>;
    else
        return <>You do not have permissions to access this application.</>;
}

export default Authorization;
